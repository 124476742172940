import React from 'react';
import ValidatorBanner from '../components/ValidatorBanner';
import NetworkOverview from '../components/NetworkOverview';
import Footer from "../components/Footer";
import BottomBanner from "../components/BottomBanner";

const Validators = () => {
  return (
      <>
          <div className="main">
              <ValidatorBanner/>
              <NetworkOverview/>
              <BottomBanner/>
          </div>
          <Footer/>
      </>
  );
};

export default Validators;
