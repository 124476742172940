import React, {useState, useRef, useEffect} from "react";
import Lottie from "react-lottie";
import text from "../assets/images/section2text.svg";
import textMobile from "../assets/images/section2TextMobile.svg";
import * as Type01 from "../assets/json/Type D.json";
import * as Type01Mobile from "../assets/json/Type Dm.json";
import * as animationRhombus from "../assets/secondScreenLottie/Asset A (1).json";
import '../css/connect.css'
import NewBtn from "./NewBtn";

const Connect = () => {
  const [showSlide, setShowSlide] = useState(false);
  const fadeRef = useRef(null);

  useEffect(() => {
    const observer2 = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
            setShowSlide(true);
            observer2.unobserve(entry.target);
        }
      });
    }, { threshold: 0.5 });
    if (fadeRef.current) {
      observer2.observe(fadeRef.current);
    }
  }, []);

  return (
    <div className="connect_blockchain">
        <div className="wrap">
            <div className="right_video">
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationRhombus,
                    }}
                    style={{
                        height: "420px",
                        width: "auto",
                        transform: "scale(1.3)"
                    }}
                />
            </div>
            <div className="left_text" ref={fadeRef}>
                <div className="lottie">
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: Type01,
                        }}
                        style={{
                            marginBottom: "24px",
                            height: "unset"
                        }}
                    />
                </div>

                <div className="lottie mobile">
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: Type01Mobile,
                        }}
                        style={{
                            marginBottom: "24px",
                            height: "unset"
                        }}
                    />
                </div>

                <img src={text} className={"text"} alt="Eoracle equips developers and consumers with the same type of programmability and security guarantees they enjoy with native smart contracts but with an added dimension: a direct, seamless connection to the real world."/>
                <img src={textMobile} className={"text mobile"} alt="Eoracle equips developers and consumers with the same type of programmability and security guarantees they enjoy with native smart contracts but with an added dimension: a direct, seamless connection to the real world."/>

                <div className={`btnWrap fromTop ${showSlide ? "active" : ""}`}>
                    <NewBtn title={"Start Validating"} link={"#"}/>
                </div>
            </div>
            <div className="elipse"/>
        </div>
    </div>
  );
};

export default Connect;
