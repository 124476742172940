import React, {useState, useEffect, useRef} from "react";
import Counter from "./Counter";
import Arrow from "../assets/images/sliderArrowBlack.png";
import Video from "../assets/video/globe_vid_main_screen.mp4";
import '../css/banner.css'
import * as MainText from "../assets/json/Type C3.json";
import * as MainTextMobile from "../assets/json/Type C2.json";
import Lottie from "react-lottie";

const Banner = () => {
  const [animationDivActive, setAnimationDivActive] = useState(false);
  const [videoActive, setVideoActive] = useState(false);
  const [animationDivRemove, setAnimationDivRemove] = useState(false);
  const [animationColRemove, setAnimationColRemove] = useState(false);
  const videoRef = useRef(null);

  useEffect(() => {
    let intervalCols;

    const timeoutvideo = setTimeout(() => {
      setVideoActive(true);
    }, 500);

    return () => {
      clearInterval(intervalCols);
      clearTimeout(timeoutvideo);
    };
  }, []);

  const handleVideoLoadedData = () => {
    const timeoutAnimationDiv = setTimeout(() => {
      setAnimationDivActive(true);
    }, 300);
    const timeoutColremove = setTimeout(() => {
      setAnimationColRemove(true);
    }, 5000);
    const timeoutremove = setTimeout(() => {
      setAnimationDivRemove(true);
    }, 7000);

  };

  const handleLearnMoreClick = () => {
    const targetElement = document.querySelector(".connect_blockchain");
    console.log(targetElement.offsetTop)

    if (targetElement) {
      window.scrollTo({
        top: targetElement.offsetTop - 100 || window.innerHeight,
        behavior: "smooth",
      });
    }
  };

  return (
      <div className="banner">
        <video
            ref={videoRef}
            autoPlay
            muted
            playsInline
            controls={false}
            preload="metadata"
            onLoadedData={handleVideoLoadedData}
            className={`img_banner ${videoActive ? "active" : ""} ${
                animationDivRemove ? "animationdone" : ""
            }  `}
        >
          <source src={Video} type="video/mp4"/>
        </video>
        <div
            className={"learnmore mobile"}
            onClick={handleLearnMoreClick}
        >
          <span>
            <img src={Arrow} alt="Arrow"/>
          </span>
        </div>
        <div className={`textslider ${animationDivActive ? "active" : ""}`}>
          <Lottie options={{
            loop: true,
            autoplay: true,
            animationData: MainText,
          }}/>
        </div>

        <div className={`mobile textslider ${animationDivActive ? "active" : ""}`}>
          <Lottie options={{
            loop: true,
            autoplay: true,
            animationData: MainTextMobile,
          }}/>
        </div>
        <div
            // className={`count_blog active`}
            className={`count_blog ${animationDivActive ? "active" : ""} ${
                animationDivRemove ? "animationdone" : ""
            } ${animationColRemove ? "noCol" : ""}`}
        >
          <h4>Ethereum Proof of Stake current state</h4>
          <div className="wrap d-flex">
            <div className="col">
              <h3>
              <span>
                <Counter value={881834} duration={2100} delay={2500}/>
              </span>
              </h3>
              <p>
                TOTAL ETHERIUM <br/> VALIDATORS
              </p>
            </div>
            <div className="col">
              <h3>
              <span>
                <Counter value={28166134} duration={2100} delay={2500}/>
              </span>
              </h3>
              <p>
                TOTAL ETHERIUM <br/> STAKED
              </p>
            </div>
            <div className="col">
              <h3>
                $
                <span>
                <Counter value={65000000000} duration={2100} delay={2500}/>
              </span>
              </h3>
              <p>
                TOTAL VALUE <br/> STAKED
              </p>
            </div>
          </div>
          <div className="animationdiv">
            <span/>
          </div>
        </div>
        <div
            className={`learnmore ${animationDivRemove ? "animationdone" : ""} `}
            onClick={handleLearnMoreClick}
        >
          <span>
            <img src={Arrow} alt="Arrow"/>
          </span>
        </div>
      </div>
  );
};

export default Banner;
