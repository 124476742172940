import React, {useEffect, useRef, useState} from 'react';
import SvgBottom from '../assets/images/validators_network.png';
import NewBtn from "./NewBtn";
import "../css/networkOverview.css"
import VideoDotsLeft from "../assets/video/dots-lnew2.mp4";

const NetworkOverview = () => {
    const fadeTextRef = useRef();
    const [showSlide, setShowSlide] = useState(false);
    const [showSlide2, setShowSlide2] = useState(false);
    const [showSlide3, setShowSlide3] = useState(false);
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setShowSlide(true);
                setTimeout(() => setShowSlide2(true), 1000);
                setTimeout(() => setShowSlide3(true), 2000);
                observer.unobserve(entry.target);
            }
        });
    }, { threshold: 0.2 });

    useEffect(() => {
        if (fadeTextRef.current) {
            observer.observe(fadeTextRef.current);
        }
    }, [fadeTextRef]);
  return (
      <div className="main network">
          <div className='network_wrapper' ref={fadeTextRef}>
              <div className="animatedTexts">
                  <h3 className={`fromTop ${showSlide ? "active" : ""}`}>
                      Maximize your Capital Efficiency with&nbsp;
                      <br/>
                      Minimal Incremental Cost
                  </h3>
                  <p className={`fromTop ${showSlide2 ? "active" : ""}`}>
                      Take part in leveraging ETH's cryptoeconomic trust, in exchange for compensation and
                      rewards. As an Eoracle validator you will operate validation services that are designed
                      to be complimentary to your existing Ethereum validation operations.
                  </p>
                  <div className={`fromTop ${showSlide3 ? "active" : ""}`}>
                      <NewBtn title={"Join our network"} isDark={true} link={"https://app.gitbook.com/o/ScGmN7KQt0fgc3h1V55E/s/QvGCmsU8FIcbArIF7C8N/"}/>
                  </div>
              </div>
              <div className="imgWrap">
              <img src={SvgBottom} className={"img"} alt=""/>
                  <video
                      className={"bg left"}
                      src={VideoDotsLeft}
                      muted={true}
                      loop={true}
                      autoPlay={true}
                      playsInline={true}
                  ></video>
                  <video
                      className={"bg right"}
                      src={VideoDotsLeft}
                      muted={true}
                      loop={true}
                      autoPlay={true}
                      playsInline={true}
                  ></video>
              </div>
          </div>
      </div>
  );
};

export default NetworkOverview;
