import "../css/subscribeForm.css"
import {useState} from "react";

const APIToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiNzVlYTlhYTE0NDlkZGRhMzMxNDI4YWRiODE2MGZhMTViNjYyZDBhNzU5MTE0NWU2NGE1NWJkMWQ0ZjJlMTM4NGNiYzljY2FmMmY5ZTFkZTEiLCJpYXQiOjE3MDcwMzI2ODkuNjU1ODk0LCJuYmYiOjE3MDcwMzI2ODkuNjU1ODk2LCJleHAiOjQ4NjI3MDYyODkuNjUyMTk1LCJzdWIiOiI4MjE1MzEiLCJzY29wZXMiOltdfQ.VaPd44-HnXM7H9kJw30lqkxByY7GOnu3YCgR2-GSnRguuR7Ocm6zEguLFRi7ylDnG3JzBgXY3JMfVHRpYiAaBkes6YOzFruk9o10XGYVAEE-uYRTF7gCIl79dPh2poga3iIte3JGnpBLKTTwwyOfIbTfvBWGD-kD5MlIzb6tOT2gQJyOM4FsakbWT2j31HHPmXazlghJzLIS8cwXMw8qfB6d8IUUHlgXnWkF3o_A6rJtGsU0hE_ywI72P68sUxxGrE4W4aU_XtUOcCT_A6KveJwbxYgIF1Yh53nQcZ3ZLUAMIiHUvkfjb7S_V9r3u1uklnbtyWBaTL4HSaQmoMQ-E8fgXAoYTDSF5C5gy0sXy07FTNTvEPhnpS2P3BsHPBeOF5ssJCb1_6jH1eG7Yl8MH_jzu2QZNnkvTwLN8hRULKndurvaA8rjOq4CFzL2l2sgZWbWsYcLVytuaJERn9-7ETDJexTSgr0wWioAHKYTcHBeoOPkKwqLJifbpI9Ny8qliLslOV_Kktud2j0OBdrmV8GDloGAcTutT3KtqdZFhkIzh-1arEBxrNv-2WPzAwj-qZ4MJZKxyCnmB8an-S2anUtnVG83wY67OvSAuMUEvGTlrcUWFMR4v1VLY3PF46p53mV2THufAuUotu0Wq08_oUeNsgyAvGQePQgkgh375IY";

const SubscribeForm = () => {

    const [formData, setFormData] = useState({
        email: ""
    });

    const subscribeToMailerLite = async (data) => {
        try {
            const response = await fetch(
                'https://connect.mailerlite.com/api/subscribers',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${APIToken}`,
                    },
                    body: JSON.stringify(data),
                }
            );

            if (!response.ok) {
                throw new Error('Failed to subscribe');
            }

            const responseData = await response.json();
            console.log(responseData);
        } catch (error) {
            console.error(error);
        }
    };

    const handleChange = (e) => {
        console.log(e.target.name, e.target.value)
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        await subscribeToMailerLite(formData);
    }

    return (
        <div className={"subscribeWrapper"}>
            <form onSubmit={handleSubmit}>
                <h5 className="heading">
                    Get the latest Eoracle content straight to your Inbox
                </h5>
                <div className="formWrap">
                    <input
                        type="email"
                        name={"email"}
                        required
                        placeholder={"Enter your Email"}
                        onChange={handleChange}
                        value={formData.email}
                    />
                    <button type={"submit"}>
                        Subscribe
                    </button>
                </div>
            </form>
        </div>
    )
}

export default SubscribeForm
