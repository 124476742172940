import React, {useEffect, useRef, useState} from "react";

import ChainVideo from "../assets/video/video3v2.mp4";
import NewBtn from "./NewBtn";
import "../css/onChain.css"

const Onchain = () => {
  const fadeTextRef = useRef();
  const [showSlide, setShowSlide] = useState(false);
  const [showSlide2, setShowSlide2] = useState(false);
  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        setShowSlide(true);
        setTimeout(() => setShowSlide2(true), 1000)
        observer.unobserve(entry.target);
      }
    });
  }, { threshold: 0.5 });

  useEffect(() => {
    if (fadeTextRef.current) {
      observer.observe(fadeTextRef.current);
    }
  }, [fadeTextRef]);
  return (
    <div className="onchain_container">
      <div className="textWrap" ref={fadeTextRef}>
        <div className={`fromTop ${showSlide ? "active" : ""}`}>
          <div className="row">
            <h4>
              Decentralized <span>Infrastructure</span>
            </h4>
          </div>
          <div className={"for"}>for</div>
          <div className="row">
            <h4>
              Decentralized <span>Networks</span>
            </h4>
          </div>
        </div>

        <div className={`fromTop ${showSlide2 ? "active" : ""}`}>
          <NewBtn title={"Learn more"} link={"https://eoracle.gitbook.io/eoracle/eoracles-aegis-blockchain"}/>
        </div>

      </div>
      <video
          autoPlay
          muted
          playsInline
          loop
          controls={false}
          preload="metadata"
      >
        <source src={ChainVideo} type="video/mp4"/>
      </video>
    </div>
  );
};
export default Onchain;
