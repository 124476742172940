import "../css/carouselTest.css"
import CardImage1 from "../assets/images/testCarouselBackgroung.png";

const CarouselTest = () => {
    return (
        <section id="slideshow">
            <div className="entire-content">
                <div className="content-carrousel">
                    <figure className="shadow">
                        <img src={CardImage1} alt=""/>
                    </figure>
                    <figure className="shadow">
                        <img src={CardImage1} alt=""/>
                    </figure>
                    <figure className="shadow">
                        <img src={CardImage1} alt=""/>
                    </figure>
                    <figure className="shadow">
                        <img src={CardImage1} alt=""/>
                    </figure>
                    <figure className="shadow">
                        <img src={CardImage1} alt=""/>
                    </figure>
                    <figure className="shadow">
                        <img src={CardImage1} alt=""/>
                    </figure>
                    <figure className="shadow">
                        <img src={CardImage1} alt=""/>
                    </figure>
                    <figure className="shadow">
                        <img src={CardImage1} alt=""/>
                    </figure>
                    <figure className="shadow">
                        <img src={CardImage1} alt=""/>
                    </figure>
                </div>
            </div>
        </section>
    )
}

export default CarouselTest
