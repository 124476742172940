import "../css/features.css";
import Feature1Img from "../assets/images/Feature1.svg";
import Feature2Img from "../assets/images/Feature2.svg";
import Feature3Img from "../assets/images/Feature3.svg";
import FeatureItem from "./FeatureItem";
import React from "react";

const Features = () => {

    const features = [
        {
            heading: "Native Security",
            details: "Replace fragmented security with the assurance of the world's most diversely trusted validator" +
                " pool. Connect smart contracts with real-world data through Ethereum’s PoS stability, scalability " +
                "and trust.",
            image: Feature1Img,
            btnText: "Learn more",
            link: "https://eoracle.gitbook.io/eoracle/extending-ethereums-proof-of-stake-pos"
        },
        {
            heading: "Maintaining <br> Value On-chain",
            details: "Rewards are earned for off-chain compute, data reporting, and consensus participation. Eoracle " +
                "cultivates a symbiotic relationship based on aligned incentives, ensuring that value is maintained" +
                " within the on-chain ecosystem.",
            image: Feature2Img,
            btnText: "Start validating ",
            link: ""
        },
        {
            heading: "Capital Efficiency",
            details: "Joining as an Ethereum validator is a seamless process, extending the use of already deposited " +
                "stake. Validators can leverage their existing node infrastructure, with only incremental additions.",
            image: Feature3Img,
            btnText: "Learn more about Restaking",
            link: "https://www.eigenlayer.xyz/"
        },
    ]

    return (
        <div className="features" id={"whyRestake"}>
            <h2 className={"sectionHeader"}>
                Why Restaking Oracle?
            </h2>

            {
                features.map((feature, index) => <FeatureItem feature={feature} key={index}/>)
            }
        </div>
    )
}

export default Features;

