import "../css/bottomBanner.css"
import NewBtn from "./NewBtn";
import React from "react";
import BG from "../assets/images/bottomBannerBG.svg"

const BottomBanner = () => {
    return (
        <div className={"bottom_banner"}>
            <div className="banner-wrap">
                <div className="content">
                    <h3>
                        <span>Take Part in The Future of Blockchain Interoperability</span>
                    </h3>
                    <div className="btnWrap">
                        <NewBtn title={"Start Validating"} link={"#"}/>
                    </div>
                </div>
                <img src={BG} className={"bg"} alt=""/>
            </div>
        </div>
    )
}

export default BottomBanner;
