import React, {useState} from "react";
import LearnMoreArrow from "../assets/images/learn_more_green.svg"
import VideoDotsLeft from "../assets/video/dots-lnew2.mp4";


const FeatureItem = ({feature}) => {
    const [expanded, setExpanded] = useState(false);
    return (
        <div className={"feature"}>
            <div className="featureWrap">
                <div
                    className={`text ${expanded ? "expanded" : ""}`}
                >
                    <div className={`heading`}>
                        <h4 dangerouslySetInnerHTML={{__html: feature.heading}}/>
                    </div>
                    <p className={`details`}>
                        {feature.details}
                        <a href={feature.link} target={"_blank"} className={"learnMore"}>
                            {feature.btnText}
                            <img src={LearnMoreArrow} alt=""/>
                        </a>
                    </p>
                    <div className="shadow"/>
                    <div className="coverVid top"/>
                    <div className="coverVid bottom"/>
                </div>

                <div className="image">
                    <img src={feature.image} alt={feature.heading}/>
                </div>

            </div>

            <video
                className={"bg left"}
                src={VideoDotsLeft}
                muted={true}
                loop={true}
                autoPlay={true}
                playsInline={true}
            ></video>
            <video
                className={"bg right"}
                src={VideoDotsLeft}
                muted={true}
                loop={true}
                autoPlay={true}
                playsInline={true}
            ></video>

        </div>
    )
}

export default FeatureItem;
