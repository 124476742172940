import React, {useEffect, useRef, useState} from 'react';
import restaker_graph from '../assets/images/restaker_graph.png';
import NewBtn from "./NewBtn";
import "../css/validatorsBanner.css";

const ValidatorBanner = () => {
    const fadeTextRef = useRef();
    const [showSlide, setShowSlide] = useState(false);
    const [showSlide2, setShowSlide2] = useState(false);
    const [showSlide3, setShowSlide3] = useState(false);
    const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                setTimeout(() => setShowSlide(true), 0)
                setTimeout(() => setShowSlide2(true), 1000)
                setTimeout(() => setShowSlide3(true), 2000)
                observer.unobserve(entry.target);
            }
        });
    }, { threshold: 0.5 });

    useEffect(() => {
        if (fadeTextRef.current) {
            observer.observe(fadeTextRef.current);
        }
    }, [fadeTextRef]);
  return (
      <div className="validator_banner" ref={fadeTextRef}>
          <div className="animatedTexts">
              <p className={`heading`}>
                  Restake, Connect, <span>Earn.</span>
              </p>
              <p className={`firstP fromTop ${showSlide ? "active" : ""}`}>
                  By becoming an Eoracle validator, you contribute to making smart contracts fully
                  interoperable
                  with real-world data. Participants report, validate, and compute off-chain data, producing
                  verifiable utility for the on-chain ecosystem.
              </p>
              <p className={`secondP fromTop ${showSlide2 ? "active" : ""}`}>
                  Join Eoracle and empower the next era of innovation by applying Ethereum's
                  programmability, security, and trust to the real world.
              </p>
              <div className={`btnWrap fromTop ${showSlide3 ? "active" : ""}`}>
                  <NewBtn title={"Connect Wallet"} link={"#"} isDark={true}/>
                  <NewBtn title={"Start Validating"} link={"#"}/>
              </div>
          </div>
          <div className='restaker_graph'>
              <img src={restaker_graph}/>
          </div>
      </div>
  );
};

export default ValidatorBanner;
