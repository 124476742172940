import React, {useState} from "react";
import ArrowDark from "../assets/images/btnArrDark.svg";
import ArrowLight from "../assets/images/btnArrLight.svg";
import "../css/newBtn.css"

const NewBtn = ({title, link, isBtn = false, isMobile = false, isDark = false}) => {
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    if (isBtn) return (
        <button type={"submit"}
                className={`newBtn ${isMobile ? "mobile" : ""} ${isDark ? "dark" : ""}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
        >
            {title}
            <img src={isDark ? ArrowDark : ArrowLight} alt=""/>
        </button>
    )

    return (
        <a target={"_blank"}
           href={link}
           className={`newBtn ${isMobile ? "mobile" : ""} ${isDark ? "dark" : ""}`}
           onMouseEnter={handleMouseEnter}
           onMouseLeave={handleMouseLeave}
        >
            {title}
            <img src={isDark ? ArrowDark : ArrowLight} alt=""/>
        </a>
    )
}

export default NewBtn;
