import "../css/footer.css"
import React from 'react'
import { Link } from 'react-router-dom';
import {scrollToAnchor} from "../helpers/ScrollToAnchor";
// import XIcon from "../assets/images/X.svg"
// import LinkedInIcon from "../assets/images/LinkedIn.svg"
// import TelegramIcon from "../assets/images/telegram.svg"
// import DiscordIcon from "../assets/images/discord.svg"
// import GitHubIcon from "../assets/images/github.svg"
import SubscribeForm from "./SubscribeForm";
const Footer = () => {
  return (
    <footer className="footer">
        <div className="container">
            <div className="top-footer">
                <div className="brand-logo">                   
                    <Link to="/" > Eoracle </Link>
                </div>
                <div className='col'>
                    <ul>
                        <li>
                            <a target={"_blank"} href='https://eoracle.gitbook.io/eoracle/'>
                                Know Eoracle
                            </a>
                        </li>
                        <li>
                            <Link to="/validators">
                                Validators
                            </Link>
                        </li>
                        {/*<li>*/}
                        {/*    <Link to="/data">*/}
                        {/*        Data/ Dapps*/}
                        {/*    </Link>*/}
                        {/*</li>*/}
                    </ul>
                </div>
                <div className='col'>
                    <ul>
                        {/*<li>*/}
                        {/*    <a target={"_blank"} href='#'>*/}
                        {/*        FAQ*/}
                        {/*    </a>*/}
                        {/*</li>*/}
                        <li>
                            <Link to="/#whyRestake" onClick={() => scrollToAnchor("whyRestake")}>
                                Why Restake?
                            </Link>
                        </li>
                        <li>
                            <a target={"_blank"} href='https://eoracle.gitbook.io/eoracle/'>
                                Docs
                            </a>
                        </li>
                    </ul>
                </div>
                <div className='col bottom'>
                    <ul>
                        <li>
                            <a target={"_blank"} href='https://eoracle.gitbook.io/eoracle/eoracles-aegis-blockchain'>
                                Aegis Blockchain
                            </a>
                        </li>
                        <li>
                            <a target={"_blank"} href='https://eoracle.gitbook.io/eoracle/technical-appendix-crypto-economic-analysis'>
                                Crypto Economic Security
                            </a>
                        </li>
                        <li>
                            <a target={"_blank"} href='https://eoracle.gitbook.io/eoracle/eoracle-operator-guide'>
                                Operator Guide
                            </a>
                        </li>
                    </ul>
                </div>
                <SubscribeForm/>
            </div>
            <div className="bottom-footer">
                {/*<ul className="social-connects">*/}
                {/*    <li><a href="#"><img src={XIcon} alt="X"/></a></li>*/}
                {/*    <li><a href="#"><img src={LinkedInIcon} alt="LinkedIn"/></a></li>*/}
                {/*    <li><a href="#"><img src={TelegramIcon} alt="Telegram"/></a></li>*/}
                {/*    <li><a href="#"><img src={DiscordIcon} alt="Discord"/></a></li>*/}
                {/*    <li><a href="#"><img src={GitHubIcon} alt="Github"/></a></li>*/}
                {/*</ul> */}
                <div className="copyright">
                    © 2024 Eoracle. All rights reserved.
                </div>
            </div>
        </div>
        <div className="elipse"/>
    </footer>
  )
}

export default Footer
