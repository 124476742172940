import React, {useEffect, useState} from "react";
import Banner from "../components/Banner";
import Connect from "../components/Connect";
import Particle from "../components/Particle";
import Onchain from "../components/Onchain";
import Subscribe from "../components/Subscribe";
import Footer from "../components/Footer";
import {scrollToAnchor} from "../helpers/ScrollToAnchor";
import Features from "../components/Features";
import BottomBanner from "../components/BottomBanner";


const Home = () => {

    useEffect(() => {
        const { hash } = window.location;

        if (hash) {
            // Remove the leading '#' from the hash
            const anchorId = hash.substring(1);
            scrollToAnchor(anchorId);
        }
    }, []);
  return (
      <div className="main">
          <Banner/>
          <Connect/>
          <Features/>
          <Onchain/>
          <BottomBanner/>
          <Footer/>
      </div>
  );
};

export default Home;
